import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { StatusFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';
import { DistributionStatusFilterKeys } from '../../../../models/Distribution';
import { PeriodicReviewStatusKeys } from '../../../../models/PeriodicReview';
import { ARCHIVED_VALUE } from '../../../activity-list/Activities';
import { formStatusKeys } from '../../../../models/ClientFormStatus';

const StatusFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const statusFilters = filter as StatusFilterValue | undefined;
  const { t } = useTranslation(['table-view', 'common', 'distribution']);

  return (
    <div className="flex flex-wrap items-center gap-2">
      {statusFilters?.clientFormStatusFilters?.map((status) => (
        <div key={status}>
          {/* Note this need to be documentStatusKeys when document space is using table view */}
          <Badge
            textClass="text-dpm-gray-2 !font-normal"
            text={`${t((status as number) === ARCHIVED_VALUE ? 'common:list.filter.archived' : formStatusKeys[status])}`}
          />
        </div>
      ))}
      {statusFilters?.periodicReviewStatusFilters?.map((status) => (
        <div key={status}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={`${t(PeriodicReviewStatusKeys[status])}`} />
        </div>
      ))}
      {statusFilters?.distributionStatusFilters?.map((status) => (
        <div key={status}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={`${t(DistributionStatusFilterKeys[status])}`} />
        </div>
      ))}
    </div>
  );
};

export default StatusFilterOverview;
